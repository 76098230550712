<template>
    <div class="grid py-28 md:grid-cols-3">
        <div class="mb-[34px] mr-[30px] flex flex-col justify-center">
            <div>
                <h2 class="mb-[30px]">
                    {{ data.heading }}
                </h2>
                <div class="mb-[30px]">
                    {{ data.description }}
                </div>
            </div>
            <div v-if="data.buttons" class="gap-x-2 md:flex">
                <Button
                    v-for="(button, key) in data.buttons"
                    :key="key"
                    :button="button"
                />
            </div>
        </div>
        <div class="order-last md:col-span-2">
            <div
                class="hidden md:row-span-2 md:row-start-1 md:block"
                :class="[
                    data.reverseLayout ? 'md:col-start-2' : 'md:col-start-1',
                ]"
            >
                <div
                    class="relative flex items-center justify-center p-28 md:h-full md:w-full md:p-12"
                >
                    <img
                        role="presentation"
                        src="~/assets/svgs/page-building/bg-phone-burst.svg"
                        class="bg-fade-grow absolute z-0 w-full scale-0"
                    />
                    <StoryblokImage
                        v-if="bagImage"
                        :src="bagImage.src"
                        :alt="bagImage.alt"
                        class="z-10 h-auto w-auto object-contain md:h-[300px] lg:h-[428px]"
                        :class="[onHover ? 'grow-image' : 'shrink-image']"
                        sizes="360px lg:766px"
                        :preload="false"
                    />
                </div>
            </div>
            <div
                class="hidden flex-wrap items-center justify-center md:block md:flex"
            >
                <div
                    v-for="(card, key) in data.cards"
                    :key="key"
                    class="margin-auto relative w-[160px]"
                    :card="card"
                >
                    <div
                        class="group relative h-[160px] w-[140px] cursor-pointer"
                        :class="[
                            selectedCard == key
                                ? 'selected-card'
                                : 'deselected-card',
                        ]"
                        @mouseenter="addAnimation(card, key)"
                        @mouseleave="removeAnimation(card)"
                        @click="addAnimation(card, key)"
                    >
                        <img
                            v-if="card.icon"
                            class="absolute z-10 aspect-square w-[140px] transition-opacity"
                            :class="{
                                'md:group-hover:opacity-0': card.hoverIcon,
                            }"
                            :src="card.icon.src"
                            :alt="card.icon.alt"
                        />
                        <img
                            v-if="card.hoverIcon"
                            class="absolute z-0 aspect-square w-[140px]"
                            :src="card.hoverIcon.src"
                            :alt="card.hoverIcon.alt"
                        />
                    </div>
                    <img
                        v-if="key < data.cards.length - 1"
                        role="presentation"
                        src="~/assets/svgs/page-building/arrow.svg"
                        class="arrow invisible absolute right-[0] top-[50px] w-[46px] md:visible"
                    />
                </div>
            </div>
            <div
                class="block flex-wrap items-center justify-center md:flex md:hidden"
            >
                <div
                    v-for="(card, key) in data.cards"
                    :key="key"
                    class="margin-auto relative w-[160px]"
                    :card="card"
                >
                    <img
                        v-if="card.image"
                        :src="card.image.src"
                        :alt="card.image.alt"
                        class="z-10 h-auto w-auto object-contain md:h-[300px] lg:h-[428px]"
                        :class="[onHover ? 'grow-image' : 'shrink-image']"
                    />
                    <img
                        v-if="card.hoverIcon"
                        class="z-0 mb-[40px] mt-[10px] aspect-square w-[140px]"
                        :src="card.hoverIcon.src"
                        :alt="card.hoverIcon.alt"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import HowItWorksShowcaseType from '~/types/HowItWorksShowcaseType';
import HowItWorksShowcaseCardType from '~/types/HowItWorksShowcaseCardType';
import Button from '~/components/page-building/components/Button.vue';

const props = defineProps<{
    data: HowItWorksShowcaseType;
}>();

const onHover = ref(false);
const selectedCard = ref(0);
const bagImage = ref(props.data.cards[0].image);

const addAnimation = (card: HowItWorksShowcaseCardType, key) => {
    onHover.value = true;
    bagImage.value = card.image;
    selectedCard.value = key;
};

const removeAnimation = (card: HowItWorksShowcaseCardType) => {
    onHover.value = false;
};
</script>

<style scoped>
.grow-image {
    transform: scale(1.1);
    animation: grow-image 0.4s ease;
}

.shrink-image {
    transform: scale(1);
    animation: shrink-image 0.5s ease;
}

.bg-fade-grow {
    opacity: 0.5;
    transform: scale(0.6);
}

.bg-fade-shrink {
    opacity: 0;
    transform: scale(0);
    animation: shrink-fade-bg 0.5s ease-in-out;
}

.selected-card {
    opacity: 1;
    transform: translate(0, -15px);
    animation: selected-card 0.4s ease;
}

.deselected-card {
    opacity: 0.5;
    animation: deselected-card 0.4s ease;
}

.arrow {
    transform: translate(20px, 0);
}

.margin-auto {
    margin: auto;
}

@keyframes grow-image {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.1);
    }
}

@keyframes shrink-image {
    from {
        transform: scale(1.1);
    }

    to {
        transform: scale(1);
    }
}

@keyframes grow-fade-bg {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(0.9);
    }
}

@keyframes shrink-fade-bg {
    from {
        opacity: 1;
        transform: scale(0.9);
    }

    to {
        opacity: 0;
        transform: scale(0);
    }
}

@keyframes selected-card {
    from {
        opacity: 0.5;
        transform: translate(0, 0px);
    }

    to {
        opacity: 1;
        transform: translate(0, -15px);
    }
}

@keyframes deselected-card {
    from {
        opacity: 1;
        transform: translate(0, -15px);
    }

    to {
        opacity: 0.5;
        transform: translate(0, 0px);
    }
}
</style>
